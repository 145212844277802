exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quickstart-js": () => import("./../../../src/pages/quickstart.js" /* webpackChunkName: "component---src-pages-quickstart-js" */),
  "component---src-pages-salesforce-js": () => import("./../../../src/pages/salesforce.js" /* webpackChunkName: "component---src-pages-salesforce-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-download-js": () => import("./../../../src/pages/success-download.js" /* webpackChunkName: "component---src-pages-success-download-js" */),
  "component---src-pages-success-newsletter-js": () => import("./../../../src/pages/success-newsletter.js" /* webpackChunkName: "component---src-pages-success-newsletter-js" */),
  "component---src-pages-success-submission-js": () => import("./../../../src/pages/success-submission.js" /* webpackChunkName: "component---src-pages-success-submission-js" */),
  "component---src-pages-tableau-js": () => import("./../../../src/pages/tableau.js" /* webpackChunkName: "component---src-pages-tableau-js" */)
}

